import React from 'react';
import TextToggle from '../components/text-toggle/text-toggle';
import WoodHome from '../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import TopBunerProduct from '../components/top-buner/top-baner-product';
import OfficeAtMap from '../components/office-on-map/office-on-map';
import ImagesView from '../components/images-view/images-view';
import Advantages from '../components/advantages/advantages';
import FormQuickOrder from '../components/form-quick-oreder/form-quick-order';
import VosstanovlenieSrubaPhoto from '../images/before-after/vosstanovlenie-sruba-do-i-posle.png';
import PokraskaDerevyannogoDomaSruba  from '../images/pokraska-sruba/pokraska-derevyannogo-doma-sruba.jpg';
import PokraskaDerevyannogoSruba  from '../images/pokraska-sruba/pokraska-derevyannogo-sruba.jpg';
import PokraskaSrubaFoto  from '../images/pokraska-sruba/pokraska-sruba-foto.jpg';
import PokraskaSrubaKraskoj  from '../images/pokraska-sruba/pokraska-sruba-kraskoj.jpg';
import PokraskaSrubaSnaruzhi  from '../images/pokraska-sruba/pokraska-sruba-snaruzhi.jpg';
import PokraskaStenSruba  from '../images/pokraska-sruba/pokraska-sten-sruba.jpg';
import PokraskaVentsovDomaSruba  from '../images/pokraska-sruba/pokraska-ventsov-doma-sruba.jpg';
import StoimostPokraskiSruba  from '../images/pokraska-sruba/stoimost-pokraski-sruba.webp';
import TsvetPokraskiSruba  from '../images/pokraska-sruba/tsvet-pokraski-sruba.jpg';


const PokraskaSruba = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Покраска сруба', url:'#', status:true}]

    const galleryImage = [
      {
        src: PokraskaDerevyannogoDomaSruba,
        width: 1,
        height: 2,
        alt: 'покраска деревянного сруба',
        title: 'покраска деревянного сруба'
      },
      {
        src: PokraskaDerevyannogoSruba,
        width: 2,
        height: 1,
        alt: 'покраска деревянного дома из сруба',
        title:'покраска деревянного дома из сруба'
      },
      {
        src: PokraskaSrubaFoto,
        width: 2,
        height: 1,
        alt: 'покраска сруба фото	',
        title: 'покраска сруба фото	'
      },
      {
        src: PokraskaStenSruba,
        width: 2,
        height: 1,
        alt: 'покраска стен сруба',
        title:'покраска стен сруба'
      },
      {
        src: PokraskaSrubaKraskoj ,
        width: 2,
        height: 3,
        alt: 'покраска сруба краской',
        title: 'покраска сруба краской'
      },
      {
        src: PokraskaSrubaSnaruzhi,
        width: 2,
        height: 3,
        alt: 'покраска сруба снаружи',
        title: 'покраска сруба снаружи'
      },
      {
        src: PokraskaVentsovDomaSruba,
        width: 2,
        height: 3,
        alt: 'покраска венцов дома из сруба',
        title: 'покраска венцов дома из сруба'
      },
      {
        src: StoimostPokraskiSruba,
        width: 3,
        height: 3,
        alt: 'стоимость покраски сруба',
        title: 'стоимость покраски сруба' 
      },
      {
        src: TsvetPokraskiSruba,
        width: 3,
        height: 2,
        alt: 'цвет покраски сруба',
        title: 'цвет покраски сруба' 
      },
    ];
    
  return (
    <div>
        <SEO title="Покраска сруба в Калининградской области"
            description="Выполним работы по аокраске сруба качественно и в срок. Работаем по договору и даем гаранти.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Покраска сруба в Калининград&shy;ской области'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
          <Container fluid>
            <Row className="mt-5">
                <Col md="6" xs='12' className="text-justify">
                  <div className="pl-md-3">
                    <h1 className="text-center text-md-left">Покраска сруба</h1>
                    <p>Бревно – один из самых популярных материалов для строительства частного жилья. 
                      Выбирают бревенчатые дома за их прочность, экологичность. Такие постройки выглядят уютно
                    и привлекательно, но со временем и под воздействием внешних факторов первоначальная
                    красота строения блекнет, а эксплуатационные характеристики постройки ухудшаются.</p>
                      <p>Кроме эстетики, окрашивание Вашего сруба сделает его устойчивым к воздействиям климатических условий,
                        разнообразием которых может похвастаться Калининградская область.
                      </p> 
                  </div>
                    <Advantages /> 
                </Col>
                <Col md="6" xs='12' className="text-center">
                  <img src={VosstanovlenieSrubaPhoto} className="w-100" style={{borderRadius:"20px", maxWidth:"500px"}}  alt="До и после покраски сруба" title="До и после покраски сруба" />
                </Col>
            </Row>
         </Container>
          <Container fluid className="price-table">
          <Container className="mt-2 p-1 p-md-3">
            <Row>
              <h2>Цены на работы по наружной окаске сруба</h2>
                <Col md="12" className="mt-2">
                  <table className="table table-striped p-table">
                  <thead>
                    <tr>
                      <th scope="col">№</th> 
                      <th scope="col">Услуга</th>
                      <th scope="col">Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Чистка водой под давлением с применением химии</td>
                      <td>от <span className="price-list">50</span> рублей за м<sup><small>2</small> </sup></td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Механическая чистка с применением инструмента, включая торцы</td>
                      <td>от <span className="price-list">350</span> рублей за м<sup><small>2</small> </sup></td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Покраска в один слой краской с грунтовкой, конопаткой (джутом)</td>
                      <td>от <span className="price-list">100</span> рублей за м<sup><small>2</small> </sup></td>
                    </tr>
                  </tbody>
                </table>
                </Col>
            </Row>
          </Container>
         </Container>
        <Container className="mt-5 mb-5">
          <Row>
            <Col>
            <h3>Покраска сократит влияние множества факторов на ваше строение из сруба.</h3>
            <h4 className="mt-2">В условиях высокой влажность древесина подвержена влияению множества факторов:</h4>
            <ul>
              <li><span className="price-list">Влага</span><br /> 
              Влага создает благоприятные условия для размножения вредных микроорганизмов, плесени,
              грибков и способствует процессу гниения.
              </li>
              <li><span className="price-list">Грибы и бактерии</span><br /> 
              Плесень и грибок способны в короткое время превратить самый свежий материал в труху.
              </li>
              <li><span className="price-list">Солнечное излучение</span><br /> 
              Солнце буквально выжигает поры, что вызывает растрескивание, причем на самых заметных местах.
              </li>
              <li><span className="price-list">Насекомые</span><br /> 
              Короеды, древоточцы, жуки-усачи и жуки-дровосеки, мебельные и домовые точильщики и т.д.
               - насекомые могут нанести существенный вред строению, особенно в совокупности с другими факторами.
              </li>
            </ul>
            </Col>
          </Row>
          </Container>
          <FormQuickOrder className="mt-5" />
          <Container className="mt-5">
          <Row>
            <Col md="12">
              <h3>Этапы работ</h3>
              <h4>при оказании услуги покраска сруба</h4>
              <p>Услуга покраски дома из сруба выполняется в несколько этапов:</p>
              <ul>
                <li>1. Определяется общий объем работ</li>
                <li>2. Определяются проблемные места постройки, которые могут требовать проведения дополнительных процедур.</li>
                <li>3. Согласовывается перечень применяемых материалов.</li>
                <li>4. Рассчитывается стоимость работ и сроки их проведения</li>
                <li>5. Подписание договора.</li>
                <li>6. Выполнение работ.</li>
                <li>7. Сдача выполненных работ заказчику.</li>
              </ul>
              <p>На каждом этапе оказания услуги, наши специалисты тесно взаимодействуют с Вами. 
                По завершению каждого этапа работ формируется отчет о проделанной работе в удобной для Вас форме.</p>
            </Col>
            <Row>
            <Col md="12">
              <h2>Профессиональные услуги по окраске сруба в Калининграде.</h2>
              </Col>
            <Col>
              <p>
                Компания «ГрутВуд» выполняет все виды работ по покраске и восстановлению срубов. 
                Мы используем только проверенные материалы, прошедшие необходимую сертификацию. 
                Все работы проводятся с обязательным соблюдением технологии и температурного режима.
              </p>
            </Col>
          </Row>
            <Col md="12">
              <h3>Покрасим ваш дом быстрои качественно!</h3>
              <h4>Выполняем работы в соответствии с технологиями.</h4>
              </Col>
            <p>Работаем только с качественными материалами и знаем как их применять. 
              Для наружной покраски дома из оцилиндрованного бревна применяются следующие красители:</p>
              <ul>
              <li><span className="price-list">Акрилатнски.</span> Устойчивы к деформациям древесины, образуют блестящее покрытие, которое долго сохраняет первоначальный вид. Хорошие отзывы получила акрилатная краска белинка.</li>
              <li><span className="price-list">Масляные растворы.</span> Они имеют прекрасную проникающую способность и не подвержены влиянию атмосферных осадков, поэтому их зачастую используют для окраски элементов находящихся в местах сосредоточения высокой влажности.</li>
              <li><span className="price-list">Антисептики.</span> Современные антисептические составы выполняют как защитные, так и декоративные функции. </li>
              </ul>
          </Row>
        </Container>
        <ImagesView galleryImage={galleryImage} />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default PokraskaSruba;